import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [2,7];

export const dictionary = {
		"/(main)": [~14,[2],[3]],
		"/(main)/ai": [15,[2,4],[3]],
		"/(main)/boards": [16,[2],[3]],
		"/(main)/groups": [17,[2,5],[3,6]],
		"/(main)/groups/[channelDisplayId]": [~18,[2,5],[3,6]],
		"/(unauth)/health": [26],
		"/(unauth)/login": [27,[12]],
		"/(unauth)/login/callback": [~28,[12]],
		"/(main)/logout": [19,[2],[3]],
		"/(unauth)/policy": [~29,[13]],
		"/(main)/profile": [20,[2,7],[3]],
		"/(main)/projects": [21,[2,8],[3,9]],
		"/(main)/projects/[projectDisplayId]": [~22,[2,8],[3,9]],
		"/(main)/talks": [23,[2,10],[3,11]],
		"/(main)/talks/[channelDisplayId]": [~24,[2,10],[3,11]],
		"/(main)/terms_and_privacy": [~25,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';